<template>
	<div>
		<a-select v-model="sender" :options="senders" />
	</div>
</template>

<script>
export default {
	props: {
		value: {
			type: String,
			required: true,
			default: "",
		},
		instance: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			sender: "",
			senders: [],
		};
	},
	computed: {},
	watch: {
		sender: {
			handler(val) {
				return this.$emit("input", val);
			},
		},
	},

	methods: {
		fetchEmailSenders() {
			this.$api
				.get(`/senders/${this.instance}`)
				.then(({ data }) => {
					this.senders = data.map(({ id, email }) => {
						return {
							label: email,
							value: id,
						};
					});
					if (!this.value && this.senders && this.senders.length) {
						return this.$emit("input", this.senders[0].value);
					}
				})
				.catch((error) => {
					console.log("Error while fetching email senders", error);
				});
		},
	},
	mounted() { },
	created() {
		this.fetchEmailSenders();
		this.sender = this.value;
	},
};
</script>

<style scoped lang="scss">
</style>
